export const BANNER_CONFS = [
	{
		title: '科技赋能，让健康管理服务更简单',
		subTitle: '依托微信生态与AIoT技术，构建健康数字化管理平台',
		image: require('../../images/banner-1.png'),
	},
	{
		title: '全域覆盖，满足各类场景下的健康管理需求',
		subTitle: '打造综合性健康管理生态，\n融合医院、体检中心、社区医院、药店，实现全方位健康服务',
		image: require('../../images/banner-2.png'),
	},
	{
		title: '全面的数字健康解决方案提供者',
		subTitle: '整合平台、小程序、医疗设备和健康穿戴产品，配合专业运营，打造全面的健康管理生态',
		image: require('../../images/banner-3.png'),
	},
];
