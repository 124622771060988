import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-049b0fff"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  class: "view-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item");
  const _component_FreeExperience = _resolveComponent("FreeExperience");
  const _component_el_carousel = _resolveComponent("el-carousel");
  return _openBlock(), _createBlock(_component_el_carousel, {
    height: "650px"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.BANNER_CONFS, (item, i) => {
      return _openBlock(), _createBlock(_component_el_carousel_item, {
        key: item
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          class: "bg",
          src: item.image
        }, null, 8, _hoisted_1), _createElementVNode("div", _hoisted_2, [_createElementVNode("h2", null, _toDisplayString(item.title), 1), _createElementVNode("h3", null, _toDisplayString(item.subTitle), 1)])]),
        _: 2
      }, 1024);
    }), 128)), _createVNode(_component_FreeExperience, {
      visible: _ctx.visibleFreeExperience,
      onHide: _cache[0] || (_cache[0] = $event => _ctx.visibleFreeExperience = false)
    }, null, 8, ["visible"])]),
    _: 1
  });
}