export const OPTIONS = [
	{
		title: '康信通',
		items: [
			{
				text: '核心产品力',
				path: '/#core-product-capabilities',
			},
			{
				text: '主要应用场景',
				path: '/#main-application-scenarios',
			},
			{
				text: '专业服务支持',
				path: '/#professional-service-support',
			},
		],
	},
	{
		title: '产品与服务',
		items: [
			{
				text: '数字健康平台',
				path: '/products-and-services/digital-health-platform',
			},
			{
				text: '用户小程序',
				path: '/products-and-services/user-mini-program',
			},
			{
				text: '小程序商城',
				path: '/products-and-services/micro-mall',
			},
			// {
			// 	text: '微客服',
			// 	path: '/products-and-services/micro-customer-service',
			// },
		],
	},
	{
		title: '行业解决方案',
		items: [
			{
				text: '健康体检中心',
				path: '/industry-solutions/post-examination-health-management',
			},
			{
				text: '医疗服务公司',
				path: '/industry-solutions/user-health-management',
			},
			{
				text: '社区服务中心',
				path: '/industry-solutions/community-health-management',
			},
			{
				text: '连锁药店',
				path: '/industry-solutions/pharmacy-chronic-disease-management',
			},


			{
				text: '医疗器械公司',
				path: '/industry-solutions/medical-device-company',
			},
			{
				text: '保险公司',
				path: '/industry-solutions/insurance-company',
			},
			{
				text: '诊所',
				path: '/industry-solutions/clinic',
			},
		],
	},
	{
		title: '客户案例',
		items: [
			{
				text: '客户介绍',
				path: '/customer-case#customer-introduction',
			},
		],
	},
	{
		title: '渠道合作',
		items: [
			{
				text: '合作权益',
				path: '/channel-cooperation#cooperation-rights',
			},
			{
				text: '适用客户',
				path: '/channel-cooperation#applicable-customers',
			},
			{
				text: '合作条件',
				path: '/channel-cooperation#cooperation-conditions',
			},
			{
				text: '合作流程',
				path: '/channel-cooperation#cooperation-process',
			},
		],
	},
	{
		title: '关于我们',
		items: [
			{
				text: '公司介绍',
				path: '/about#company-introduction',
			},
			{
				text: '联系我们',
				path: '/about#contact-us',
			},
		],
	},
];
